html,
body {
  margin: 0;
  padding: 0;
}

input[type='text'],
input[type='email'] {
  padding-right: 0;
}
